var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Tabs',{attrs:{"tabs":_vm.tabs,"color":"var(--primary-2-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButtonMenu',{attrs:{"type":"secundario","options":_vm.planilhaOptions},on:{"click":function (op) { return _vm.selectOperacao(op); }}},[_vm._v(" Operação em planilha ")]),_c('FormButtonMenu',{attrs:{"type":"primario","options":_vm.operacoesOptions},on:{"click":function (op) { return _vm.selectOperacao(op); }}},[_vm._v(" Novo ")])],1)]},proxy:true},{key:"agrupador",fn:function(){return [_c('DataTable',{ref:"tableAgrupador",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"name","sortDesc":true,"search":""},on:{"click":function (item) { return _vm.onClick(item, true); }},scopedSlots:_vm._u([{key:"local_uso",fn:function(ref){
var col = ref.col;
return [(col.includes('filtro_busca') || !col)?_c('span',{staticClass:"local-uso filtro"},[_vm._v("Filtros de busca")]):_vm._e(),(col.includes('detalhe_produto'))?_c('span',{staticClass:"local-uso detalhes"},[_vm._v("Detalhes produto")]):_vm._e(),(col.includes('card_produto'))?_c('span',{staticClass:"local-uso card"},[_vm._v("Card produto")]):_vm._e()]}},{key:"tags_pesquisa",fn:function(ref){
var col = ref.col;
return [_vm._l((_vm.filtraTags(col)),function(tag){return _c('span',{key:tag,staticClass:"tag-pesquisa"},[_vm._v(_vm._s(tag))])}),(col.length > 4)?_c('span',[_vm._v(" + "+_vm._s(col.length - 4)+" tags")]):_vm._e()]}},{key:"idagrupadortagpesquisa",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, true, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})]},proxy:true},{key:"tag",fn:function(){return [_c('DataTable',{ref:"tableTags",attrs:{"headers":_vm.headersTags,"endpoint":_vm.endpointTags,"sortBy":"name","sortDesc":true,"search":""},on:{"click":function (item) { return _vm.onClick(item); }},scopedSlots:_vm._u([{key:"idtagpesquisa",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, false, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})]},proxy:true}])}),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[(_vm.modalEdicaoEmPlanilha.open)?_c('EdicaoEmPlanilha',{attrs:{"modelo":_vm.modalEdicaoEmPlanilha.modelo},on:{"close":function($event){_vm.modalEdicaoEmPlanilha.open = false}}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }