<template>
  <Panel :lineColor="lineColor">
    <template v-slot:options><slot name="options"></slot></template>
    <template v-slot:content-full>
      <v-tabs v-model="model">
        <v-tabs-slider :color="color"></v-tabs-slider>
        <v-tab v-for="item in tabs" :key="item.key">
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <div class="content-card">
        <v-tabs-items v-model="model">
          <v-tab-item v-for="item in tabs" :key="item.key">
            <slot :name="item.key"></slot>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import Panel from '@/components/Panel'
export default {
  name: 'Tabs',
  components: { Panel },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    width: {
      type: String,
      default: '100%',
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    shadown: {
      type: Boolean,
      default: false,
      required: false
    },
    start: {
      type: Number,
      default: 0,
      required: false
    },
    color: {
      type: String,
      default: 'var(--primary-color)',
      required: false
    },
    lineColor: {
      type: String,
      default: 'var(--primary-color)',
      required: false
    }
  },
  computed: {
    ...mapGetters(['darkEnabled'])
  },
  data: () => ({
    model: 0
  }),
  created() {
    this.model = this.start
  },
  watch: {
    start(val) {
      this.model = val
    }
  }
}
</script>

<style lang="scss" scoped>
.card-default {
  border-radius: 8px;
  .content-card {
    padding: 15px;
  }
}
</style>
