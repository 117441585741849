<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Tabs :tabs="tabs" color="var(--primary-2-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButtonMenu
            type="secundario"
            :options="planilhaOptions"
            @click="op => selectOperacao(op)"
          >
            Operação em planilha
          </FormButtonMenu>
          <FormButtonMenu
            type="primario"
            :options="operacoesOptions"
            @click="op => selectOperacao(op)"
          >
            Novo
          </FormButtonMenu>
        </FormButtonGroup>
      </template>
      <template v-slot:agrupador>
        <DataTable
          :headers="headers"
          :endpoint="endpoint"
          sortBy="name"
          :sortDesc="true"
          @click="item => onClick(item, true)"
          search
          ref="tableAgrupador"
        >
          <template v-slot:local_uso="{ col }">
            <span
              v-if="col.includes('filtro_busca') || !col"
              class="local-uso filtro"
              >Filtros de busca</span
            >
            <span
              v-if="col.includes('detalhe_produto')"
              class="local-uso detalhes"
              >Detalhes produto</span
            >
            <span v-if="col.includes('card_produto')" class="local-uso card"
              >Card produto</span
            >
          </template>
          <template v-slot:tags_pesquisa="{ col }">
            <span
              v-for="tag in filtraTags(col)"
              :key="tag"
              class="tag-pesquisa"
              >{{ tag }}</span
            >
            <span v-if="col.length > 4"> + {{ col.length - 4 }} tags</span>
          </template>
          <template v-slot:idagrupadortagpesquisa="{ row }">
            <FormButtonMenu
              icon
              :options="tableOptions"
              @click="op => selectOption(row, true, op)"
            >
              <v-icon size="15">fas fa-ellipsis-v</v-icon>
            </FormButtonMenu>
          </template>
        </DataTable>
      </template>
      <template v-slot:tag>
        <DataTable
          :headers="headersTags"
          :endpoint="endpointTags"
          sortBy="name"
          :sortDesc="true"
          @click="item => onClick(item)"
          search
          ref="tableTags"
        >
          <template v-slot:idtagpesquisa="{ row }">
            <FormButtonMenu
              icon
              :options="tableOptions"
              @click="op => selectOption(row, false, op)"
            >
              <v-icon size="15">fas fa-ellipsis-v</v-icon>
            </FormButtonMenu>
          </template>
        </DataTable>
      </template>
    </Tabs>

    <transition name="slide-popup" mode="out-in">
      <EdicaoEmPlanilha
        v-if="modalEdicaoEmPlanilha.open"
        :modelo="modalEdicaoEmPlanilha.modelo"
        @close="modalEdicaoEmPlanilha.open = false"
      />
    </transition>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs'
import DataTable from '@/components/DataTable'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import { mapGetters } from 'vuex'
import {
  TAGAGRUPADOR_DELETA,
  TAGPRODUTO_DELETA
} from '@/store/actions/tagsProduto'
import EdicaoEmPlanilha from '@/components/EdicaoEmPlanilha'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'TagsProdutos',
  components: {
    Tabs,
    DataTable,
    FormButtonMenu,
    FormButtonGroup,
    EdicaoEmPlanilha,
    MarketingModules
  },
  data: () => ({
    tabs: [
      {
        key: 'agrupador',
        text: 'Agrupadores'
      },
      {
        key: 'tag',
        text: 'Tags'
      }
    ],
    headers: [
      {
        text: 'Nome do agrupador',
        value: 'descricao'
      },
      {
        text: 'Local de exibição',
        value: 'local_uso'
      },
      {
        text: 'Ordenação',
        value: 'ordenacao'
      },
      {
        text: 'Tags vinculadas',
        value: 'tags_pesquisa'
      },
      {
        text: 'Opções',
        value: 'idagrupadortagpesquisa',
        clickable: false
      }
    ],
    headersTags: [
      {
        text: 'Tag',
        value: 'valor'
      },
      {
        text: 'Nome interno',
        value: 'descricao'
      },
      {
        text: 'Opções',
        value: 'idtagpesquisa',
        clickable: false
      }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    operacoesOptions: [
      { text: 'Agrupador de tags', value: 'agrupador' },
      { text: 'Tag de produto', value: 'tag' }
    ],
    planilhaOptions: [
      { text: 'Edição agrupador tags', value: 'planilha_agrupador_tag' },
      { text: 'Edição tags', value: 'planilha_tag_pesquisa' }
    ],
    modalEdicaoEmPlanilha: {
      open: false,
      modelo: 'planilha_tag_pesquisa'
    },
    modulosForm: [
      ModulesEnum.PORTFOLIO_WEB,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/tag-pesquisa/${this.getFornecedorId}/agrupador/datatable`
    },
    endpointTags() {
      return `/api/v4/tag-pesquisa/${this.getFornecedorId}/datatable`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    filtraTags(tags) {
      return tags.length > 4 ? tags.slice(0, 4) : tags
    },
    onClose() {
      this.$refs.tableAgrupador.reload()
      this.$refs.tableTags.reload()
    },
    onClick(item, agrupador = false) {
      if (agrupador) {
        this.$router.push(
          `tag-produto/agrupador/${item.idagrupadortagpesquisa}`
        )
      } else {
        this.$router.push(`tag-produto/tag/${item.idtagpesquisa}`)
      }
    },
    selectOption(item, agrupador, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item, agrupador)
      }
    },
    onDelete(item, agrupador = false) {
      this.$swal
        .fire({
          title: `Remover ${
            agrupador ? 'agrupador de tags' : 'tag de produto '
          }? `,
          text: 'Essa ação é irreversível',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) this.delete(item, agrupador)
          else return
        })
    },
    delete(item, agrupador = false) {
      if (agrupador) {
        this.$store
          .dispatch(TAGAGRUPADOR_DELETA, item.idagrupadortagpesquisa)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Agrupador de tags removido com sucesso'
            )
            this.$refs.tableAgrupador.reload()
          })
      } else {
        this.$store.dispatch(TAGPRODUTO_DELETA, item.idtagpesquisa).then(() => {
          this.$vueOnToast.pop('success', 'Tag de produto removido com sucesso')
          this.$refs.tableAgrupador.reload()
        })
      }
    },
    selectOperacao(opt) {
      if (opt.value.startsWith('planilha')) {
        this.modalEdicaoEmPlanilha = {
          open: true,
          modelo: opt.value
        }
        return
      } else if (opt.value === 'agrupador') {
        this.$router.push('tag-produto/agrupador/novo')
      } else {
        this.$router.push('tag-produto/tag/novo')
      }
    }
  }
}
</script>

<style>
.local-uso,
.tag-pesquisa {
  padding: 5px;
  margin: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
}
.local-uso.filtro {
  background-color: var(--info-color);
}
.local-uso.detalhes {
  background-color: var(--secondary-color);
}
.local-uso.card {
  background-color: var(--primary-2-color);
}
.tag-pesquisa {
  background-color: var(--auxiliar-color);
}
</style>
